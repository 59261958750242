/* eslint-disable array-callback-return */

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import HeroSubpage from "components/HeroSubpage"

import { Services, Contact } from "page_components/home"
import {
  Agency,
  SeaForwarding,
  AirForwarding,
  RailwayForwarding,
  Ecommerce,
  CustomsWarehouse,
  Transport,
  Logistic,
} from "page_components/services"

const ServicesPage = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]
  const servicesData = data.allWpService.nodes

  return (
    <Layout
      seo={{
        title: pageData?.title,
      }}
      location={location}
      services={servicesData}
    >
      <HeroSubpage
        title={pageData?.title}
        description={pageData?.acfServices?.heroDescription}
      />
      <Services pageData={servicesData} isInverted />
      {servicesData?.map(service => {
        if (service.slug === "logistyka-kontraktowa") {
          return <Logistic key={service.slug} data={service} />
        } else if (service.slug === "transport-i-spedycja-drogowa") {
          return <Transport key={service.slug} data={service} />
        } else if (service.slug === "spedycja-morska") {
          return <SeaForwarding key={service.slug} data={service} />
        } else if (service.slug === "spedycja-lotnicza") {
          return <AirForwarding key={service.slug} data={service} />
        } else if (service.slug === "transport-intermodalny") {
          return <RailwayForwarding key={service.slug} data={service} />
        } else if (service.slug === "uslugi-dla-e-commerce") {
          return <Ecommerce key={service.slug} data={service} />
        } else if (service.slug === "agencja-celna") {
          return <Agency key={service.slug} data={service} />
        } else if (service.slug === "sklad-celny") {
          return <CustomsWarehouse key={service.slug} data={service} />
        }
      })}
      <Contact isSubpage />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String) {
    allWpPage(filter: { id: { eq: "cG9zdDoxNDk=" } }) {
      nodes {
        id
        title
        acfServices {
          heroDescription
        }
      }
    }

    allWpService(
      filter: { language: { slug: { eq: $language } } }
      sort: { fields: date }
    ) {
      nodes {
        id
        title
        slug
        acfServices {
          tileImage {
            sourceUrl
          }
          offerDescription
          offerDescriptionBottom
          offerDetails {
            description
            title
          }
          offerImage {
            sourceUrl
          }
        }
        acfServicesAdditionalFields {
          numbersHeader
          servicesHeader
          numbersList {
            description
            descriptionHighlight
            header
          }
          servicesImage {
            sourceUrl
          }
          servicesList {
            name
          }
        }
      }
    }
  }
`

export default ServicesPage
